import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name : 'home',
            component: () => import('../views/Home.vue')
        },
        {
            path: '/register',
            component: () => import('../views/Register.vue')
        },
        {
            path: '/sign-in',
            component: () => import('../views/SignIn.vue')
        },
        {
            path: '/userx',
            component: () => import('../views/Userx.vue'),
        },
        {
            path: '/feed',
            component: () => import('../views/Feed.vue'),
            
        },
    ]
})

export default router


