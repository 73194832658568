import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isAuthenticated: false,
    name: '',
    url: '',
    email:'',
    token:'',
  },
  mutations: {
    setIsAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
    setName(state, value) {
      state.name = value;
    },
    setUrl(state, value) {
      state.url = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
  },
  actions: {
    login({ commit }) {
      // Perform your authentication logic here
      commit('setIsAuthenticated', true);
    },
    logout({ commit }) {
      // Perform your logout logic here
      commit('setIsAuthenticated', false);
    },
  },
  plugins: [createPersistedState()], // This enables state persistence
});